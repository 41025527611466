import api from '@utils/api';

export const fetchMatch = (id) => {
  return api.get(`v2/match/${id}`);
};

export const fetchMatchAbilities = (id) => {
  return api.get(`/match/${id}/abilities`);
};

export const fetchMatchHeroesPickStatus = (id) => {
  return api.get(`/match/${id}/heroes/pick/status`);
};

export const sendMatchHeroesPickBan = ({ id, ban }) => {
  return api.post(`/match/${id}/heroes/pick/ban`, { ban });
};

export const fetchMatchMapPickStatus = (id) => {
  return api.get(`/v2/match/${id}/map/pick/status`);
};

export const sendMatchMapPick = ({ id, value, action, sideCode }) => {
  return api.post(`/v2/match/${id}/map/pick/action`, {
    class: sideCode,
    action,
    value,
  });
};

export const fetchMatchCheckinStatus = (id) => {
  return api.get(`/match/${id}/checkin/status`);
};

export const sendMatchCheckinConfirm = (id) => {
  return api.post(`/match/${id}/checkin/confirm`);
};

export const fetchMatchChannels = (id) => {
  return api.get(`/match/${id}/channels`);
};

export const fetchMatchStatistics = (id) => {
  return api.get(`/v2/match/${id}/stats`);
};

export const sendMatchResult = ({
  id,
  specialStatus,
  complaint,
  pointsTeamOne,
  pointsTeamTwo,
  imgScreens,
}) => {
  return api.post(`/match/${id}/result/change`, {
    form: {
      specialStatus,
      complaint,
      pointsTeam1: pointsTeamOne,
      pointsTeam2: pointsTeamTwo,
      imgScreens: {
        add: imgScreens,
      },
    },
  });
};

export const fetchMatchResult = (id) => {
  return api.get(`/match/${id}/result/items`);
};

export const fetchMatchJudgeResult = (id) => {
  return api.get(`/v2/match/${id}/judge/result`);
};

export const sendMatchJudgeResult = ({
  matchId,
  gameId,
  results,
  imgScreens,
}) => {
  return api.post(
    `/v2/match/${matchId}/judge/result/${gameId}/save`,
    {
      results,
      imgScreens,
    },
  );
};
