<template>
  <main-layout class="padded" :is-loading="loadingState.page">
    <div
      v-if="match && tournament"
      :key="match.id"
      class="match pvp-container"
    >
      <div class="match-head">
        <div class="head-title">{{ $t('matches.single') }}</div>
        <TournamentLink :id="tournament.id" class="head-link">
          {{ $t('tournaments.to_single') }}
          <icon name="arrow-right" />
        </TournamentLink>
      </div>
      <div class="match-box">
        <div class="box-left">
          <h1 class="match-title">{{ tournament.name }}</h1>
          <game-tag :id="tournament.idGame" />
        </div>
        <BaseSocialLinks
          class="match-soc"
          has-copy-link
          :share-title="shareTitle"
        />
      </div>
      <component
        :is="matchType"
        v-if="matchType"
        :id="matchId"
        :round="round"
        :data="matchData"
        @update="handleMatchUpdate"
      />
      <p v-else class="freeSlot">
        {{ $t('matches.notExist') }}
      </p>
    </div>

    <template slot="seo-text">
      <p>{{ seoText }}</p>
    </template>
  </main-layout>
</template>

<script>
import MatchCommon from '@components/Match/Common/Match.vue';
import MatchTeamFFA from '@components/Match/TeamFFA/Match.vue';
import Icon from '@components/v2/utils/Icon.vue';
import BaseSocialLinks from '@components/BaseComponents/BaseSocialLinks.vue';
import TournamentLink from '@components/BaseComponents/TournamentLink.vue';

export default {
  components: {
    Icon,
    MatchCommon,
    MatchTeamFFA,
    BaseSocialLinks,
    TournamentLink,
  },
  page() {
    return {
      title: this.metaTags.title,
      meta: [
        {
          description: this.metaTags.description,
        },
      ],
    };
  },
  props: {
    matchId: {
      type: Number,
      required: true,
    },
    round: {
      type: Object,
      required: true,
    },
    matchData: {
      type: [Object, Boolean],
      required: true,
    },
  },
  computed: {
    ...mapGetters('tournaments', ['getTournament']),
    ...mapGetters('users', ['getUserMatchStatus']),
    ...mapGetters('profile', ['isLoggedIn']),
    ...mapGetters('matches', ['getMatch']),
    ...mapGetters('teams', ['getTeam']),
    ...mapGetters('application', ['getGame']),

    seoText() {
      return this.$t('seo.match', {
        host: window.location.host.toLocaleUpperCase(),
      });
    },
    match() {
      return this.getMatch(this.matchId);
    },
    isParticipant() {
      const { roles } = this.getUserMatchStatus(this.matchId);
      return roles?.participant;
    },
    tournament() {
      return this.getTournament(this.match?.idTournament);
    },
    game() {
      return this.getGame(this.tournament?.idGame);
    },
    tournamentSystem() {
      return this.tournament?.system;
    },
    shareTitle() {
      return this.$t('matches.shareTitle', {
        tournament: this.tournament?.name,
        host: window.location.host.toLocaleUpperCase(),
      });
    },
    matchType() {
      if (this.tournamentSystem?.code === 'team_ffa') {
        return 'MatchTeamFFA';
      }
      if (
        this.match?.teams?.some(
          (team) => team?.hash === this.freeSlot.hash,
        )
      ) {
        return '';
      }
      return 'MatchCommon';
    },
    metaTags() {
      const teams = _.get(this.match, 'teams', null);

      if (
        !this.match ||
        !this.tournament ||
        !teams ||
        !teams.length
      ) {
        return {
          title: this.$t('matches.single'),
          description: this.$t('matches.single'),
        };
      }

      if (this.tournamentSystem?.code === 'team_ffa') {
        return {
          title: `${this.tournament.name}: ${this.match.name}, ${this.round.name}`,
          description: this.$t('matches.single'),
        };
      }

      const [leftName, rightName] = teams.map((team) =>
        _.get(this.getTeam(team.hash), 'name', ''),
      );

      return {
        title: `${this.$t(
          'matches.single',
        )}: ${leftName} vs ${rightName}`,
        description: this.$t('matches.metaDescription', {
          team1: leftName,
          team2: rightName,
          tournament: this.tournament.name,
        }),
      };
    },
  },
  watch: {
    matchId: {
      async handler() {
        this.fetchMatch(this.matchId);
        this.init();
      },
    },
  },
  mounted() {
    this.fetchMatch(this.matchId);
    this.init();
  },
  methods: {
    ...mapActions('hearthstone', ['fetchHSCards']),
    ...mapActions('matches', ['fetchMatch']),
    ...mapActions('matches', [
      'fetchMatchUserAbilities',
      'fetchMatchChannels',
    ]),

    async init() {
      this.loadingState.page = true;
      if (!this.matchData) {
        this.$router.push({
          name: 'not-found',
        });
        return;
      }

      const promises = [this.fetchMatchUserAbilities(this.matchId)];
      if (this.isLoggedIn) {
        if (this.game?.code === 'hs') {
          promises.push(this.fetchHSCards());
        }
        this.fetchMatchChannels(this.matchId);
      }
      await Promise.all(promises);
      this.loadingState.page = false;
    },

    handleMatchUpdate() {
      this.$emit('update');
    },
  },
};
</script>

<style lang="scss" scoped>
.match {
  &-head {
    display: flex;
    align-items: center;

    .head {
      &-title {
        font-size: 32px;
        line-height: normal;
        font-weight: bold;
        @include min-laptop() {
          font-size: 34px;
        }
      }

      &-link {
        font-size: 16px;
        line-height: normal;
        @include min-laptop() {
          margin-left: 30px;
        }
        @include max-laptop() {
          margin-left: 20px;
        }

        .icon {
          width: 12px;
          height: 12px;
        }
      }
    }
  }

  &-title {
    font-size: 20px;
    font-weight: bold;
    @include min-laptop() {
      font-size: 48px;
    }
  }

  &-box {
    @include min-laptop() {
      margin-top: 33px;
      display: flex;
      justify-content: space-between;
    }

    .box-left {
      padding-right: 16px;
    }
  }

  &-soc {
    @include max-laptop() {
      margin-top: 20px;
    }
  }
}

::v-deep {
  .section {
    margin-top: 50px;

    &-title {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 20px;
      display: block;
    }
  }
}

.freeSlot {
  margin-top: 20px;
  text-align: center;
}
</style>
