<template>
  <div class="match">
    <box class="match-head">
      <span class="match-title">
        {{ round.name }}, {{ match.name }}
      </span>
      <span v-if="matchStatus.text" class="match-state">
        {{ matchStatus.text }}
      </span>
      <div class="match-wrapper">
        <div
          v-if="match.description || round.description"
          class="match-description"
        >
          <InfoBox v-if="round.description">
            <!-- eslint-disable vue/no-v-html -->
            <div v-html="round.description"></div>
          </InfoBox>
          <InfoBox v-if="match.description">
            <div v-html="match.description"></div>
          </InfoBox>
        </div>

        <tabs-menu
          v-model="currentGameIndex"
          class="ratings-tabs"
          :list="gameTabs"
        />

        <div class="match-panel">
          <ServerInfo
            v-if="ServerInfo.isVisible"
            :info="ServerInfo.server"
          />

          <div
            v-else-if="matchStartWait && match.tsStartTime"
            class="info-box"
          >
            <span class="info-title"
              >{{ $t('matches.checkin_beforeStart_title') }}:</span
            >
            <time-left
              :to="match.tsStartTime"
              :is-server-time="true"
            />
          </div>

          <JudgeInfo
            v-else-if="isJudge"
            :id="id"
            :key="currentGameIndex"
            v-model="isEditMode"
            :match="match"
            :match-status="matchStatus"
            :game="currentGameIndex"
            @input="currentGameIndex = 0"
            @update="$emit('update')"
          />
        </div>

        <template v-if="canUploadResults && currentGameIndex">
          <div class="participant-results">
            <loader v-if="currentGameIsLoading" />
            <template v-else>
              <div class="results-upload-button">
                <pvp-btn
                  :is-loading="screenshotsModalOpened"
                  @click="uploadScreenshots"
                  >{{ $t('matches.results_upload') }}
                </pvp-btn>
              </div>

              <div
                v-if="
                  currentGameResults &&
                  currentGameResults.imgScreens.length
                "
                class="results-info"
              >
                <div class="results-info-title">
                  <i18n path="screenshots.uploaded_text">
                    <b slot="count">
                      {{
                        $tc(
                          'screenshots.uploaded_count',
                          currentGameResults.imgScreens.length,
                        )
                      }}
                    </b>
                  </i18n>
                </div>
                <div
                  v-if="currentGameResults.imgScreens.length"
                  class="screenshots-list"
                >
                  <div
                    v-for="(
                      screen, index
                    ) in currentGameResults.imgScreens"
                    :key="screen.id"
                    class="list-item"
                  >
                    <pvp-btn
                      variant="link"
                      @click="loadImage(screen.id)"
                    >
                      {{
                        $t('screenshots.open', { n: `#${index + 1}` })
                      }}
                    </pvp-btn>
                  </div>
                </div>
              </div>
            </template>
          </div>

          <screenshots-upload-modal
            v-if="screenshotsModalOpened"
            v-model="screenshotsModalOpened"
            :game-id="currentGame.id"
            :match-id="match.id"
            @uploaded="fetchGameResults"
          />
        </template>
      </div>
    </box>

    <JudgeTable
      v-if="isEditMode"
      :id="id"
      :game="currentGameIndex"
      :title="$t('participants.few')"
      :match="match"
      @update="$emit('update')"
    />
    <template v-else>
      <template v-if="currentGameIndex">
        <Participants
          v-if="teams.length"
          :teams="teams"
          :disable-kill-points="match.disableMatchKills"
          :disable-round-points="match.disableRoundPoints"
        />
      </template>
      <template v-else>
        <Participants
          v-if="teams.winners.length"
          :title="$t('participants.status_nextRound')"
          :teams="teams.winners"
          :disable-kill-points="match.disableMatchKills"
          :disable-round-points="match.disableRoundPoints"
        />

        <Participants
          v-if="teams.losers.length"
          :title="
            matchStatus.inProgress || matchStatus.isEnded
              ? $t('participants.status_leaveTournament')
              : $t('participants.few')
          "
          :teams="teams.losers"
          :disable-kill-points="match.disableMatchKills"
          :disable-round-points="match.disableRoundPoints"
        />
      </template>
    </template>

    <pvp-modal
      :value="!!loadedImg"
      class="full-width"
      @input="closeModal"
    >
      <img class="loaded-img" :src="loadedImg" />
    </pvp-modal>
  </div>
</template>

<script>
import Participants from '@components/Match/TeamFFA/ParticipantsTable.vue';
import ServerInfo from '@components/Match/TeamFFA/ServerInfo.vue';
import ScreenshotsUploadModal from '@components/Match/TeamFFA/ScreenshotsUploadModal.vue';
import JudgeInfo from '@components/Match/TeamFFA/Judge/JudgeInfo.vue';
import JudgeTable from '@components/Match/TeamFFA/Judge/JudgeTable.vue';
import InfoBox from '@components/BaseComponents/InfoBox.vue';
import { useTimeStore } from '@src/shared/store/useTimeStore';

export default {
  components: {
    JudgeTable,
    JudgeInfo,
    ScreenshotsUploadModal,
    Participants,
    ServerInfo,
    InfoBox,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    round: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const timeStore = useTimeStore();
    timeStore.fetchServerTime();
    return { timeStore };
  },
  data: () => ({
    loadedImg: '',
    gameResultsLoading: {},
    gameResults: {},

    currentGameIndex: 0,
    isEditMode: false,

    screenshotsModalOpened: false,
  }),
  computed: {
    ...mapGetters('application', [
      'getWfShard',
      'getPubgShard',
      'getGame',
    ]),
    ...mapGetters('matches', ['getMatch', 'getMatchGameStatus']),
    ...mapGetters('teams', ['getTeam']),
    ...mapGetters('users', ['getUserMatchStatus']),
    ...mapGetters('tournaments', ['getTournament']),

    match() {
      return this.getMatch(this.id);
    },

    matchStartWait() {
      const { judge, participant, organization } =
        this.userStatus?.roles || {};
      return (
        this.match.tsStartTime > this.timeStore.serverTime &&
        (judge || participant || organization)
      );
    },

    tournament() {
      return this.getTournament(this.match.idTournament);
    },

    userStatus() {
      return this.getUserMatchStatus(this.id);
    },

    isJudge() {
      return this.userStatus?.roles?.judge;
    },

    isPlayer() {
      return (
        this.userStatus?.roles?.participant ||
        this.userStatus?.roles?.captain
      );
    },

    matchGames() {
      return this.match.games.map((game) => ({
        ...game,
        id: game.idGame,
      }));
    },

    currentGame() {
      return this.matchGames[this.currentGameIndex - 1];
    },

    currentGameIsLoading() {
      return (
        this.currentGame &&
        this.gameResultsLoading[this.currentGame.id]
      );
    },

    currentGameResults() {
      return (
        this.currentGame && this.gameResults[this.currentGame.id]
      );
    },

    canUploadResults() {
      return (
        !this.tournament.rentServers &&
        this.currentGame?.status === 4 &&
        this.isPlayer &&
        this.userStatus.abilities.canEditTeamFfaResult
      );
    },

    teams() {
      const slots = _.get(this.match, 'games[0].results', []).reduce(
        (acc, result) => ({
          ...acc,
          [result.hash]: result.slot,
        }),
        {},
      );

      const teams = this.match.teams.reduce(
        (acc, team) => ({
          ...acc,
          [team.hash]: {
            ...team,
            ...this.getTeam(team.hash),
            slot: slots[team.hash],
          },
        }),
        {},
      );

      if (this.currentGameIndex) {
        return _.sortBy(
          this.match.games[this.currentGameIndex - 1].results.map(
            (result) => ({
              ...teams[result.hash],
              ...result,
            }),
          ),
          'place',
        );
      }

      return {
        losers: Object.values(teams).filter(
          (team) => !team.isMovedNextRound,
        ),
        winners: Object.values(teams).filter(
          (team) => team.isMovedNextRound,
        ),
      };
    },

    gameTabs() {
      const tabs = this.match.games.map((game) => ({
        title: `${this.$t('games.single')} ${game.index + 1}`,
      }));
      return this.isEditMode
        ? tabs
        : [{ title: this.$t('games.all') }].concat(tabs);
    },

    matchStatus() {
      const now = new Date().getTime() / 1000;
      const start = this.match.tsStartTime;
      const end = this.match.tsEndTime;
      const date = {
        text: '',
        isEnded: false,
        inProgress: false,
      };

      const setDateText = (text, ts) =>
        `${text} ${this.$dt(ts, 'dd_MM_YYYY_HH_mm')}`;

      if (start && now < start) {
        date.text = setDateText(
          this.$t('matches.dateStatus_held'),
          start,
        );
      } else if (end && start && now >= start && now < end) {
        date.inProgress = true;
        date.text = setDateText(
          this.$t('matches.dateStatus_started'),
          start,
        );
      } else if (end && now >= end) {
        date.isEnded = true;
        date.text = setDateText(
          this.$t('matches.dateStatus_completed'),
          end,
        );
      }

      return date;
    },

    ServerInfo() {
      const currentGame = this.match.games[this.currentGameIndex - 1];
      if (currentGame === undefined) {
        return {
          isVisible: false,
        };
      }

      const isVisible =
        this.currentGameIndex &&
        !this.isEditMode &&
        currentGame.params?.['server_name'];
      const server = { ...currentGame.params };
      server.idGame = this.tournament.idGame;
      const game = this.getGame(this.tournament.idGame);

      if (game?.code === 'pub') {
        server.server_region = this.getPubgShard(
          server.server_region,
        )?.label;
      }

      if (game?.code === 'wf') {
        server.server_region = this.getWfShard(
          server.server_region,
        )?.label;
      }

      return {
        isVisible,
        server,
      };
    },
  },
  watch: {
    currentGameIndex: 'fetchGameResults',
  },
  created() {
    const game = this.match.games.find(
      ({ status }) => status === 2 || status === 3,
    );
    this.currentGameIndex = game ? game.index + 1 : 0;
  },
  methods: {
    uploadScreenshots() {
      this.screenshotsModalOpened = true;
    },

    closeModal() {
      this.loadedImg = '';
    },

    fetchGameResults() {
      if (this.currentGame && this.currentGame.status === 4) {
        const gameId = this.currentGame.id;

        this.$set(this.gameResultsLoading, gameId, true);
        this.$set(this.gameResults, gameId, null);

        api
          .get(`/match/${this.match.id}/player/result/${gameId}`)
          .then((result) => {
            this.$set(this.gameResults, gameId, result);
          })
          .finally(() => {
            this.$set(this.gameResultsLoading, gameId, false);
          });
      }
    },

    loadImage(screenId) {
      api
        .get(
          `/match/${this.id}/player/result/${this.currentGame.id}/screen/${screenId}`,
        )
        .then((data) => {
          this.loadedImg = data;
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.participant-results {
  text-align: center;
  background: rgba(black, 0.1);
  padding: 30px 0;

  .results-info {
    margin-top: 15px;
  }

  .screenshots-list {
    margin-top: 10px;
  }

  .loader {
    margin: 0 auto;
  }
}

.match {
  margin-top: 30px;

  &-head {
    box-shadow: none;
    @include min-laptop() {
      padding: 30px 30px 0;
    }
    @include max-laptop() {
      padding-top: 20px;
    }
    @include max-tablet() {
      margin-left: -12px;
      margin-right: -12px;
    }
  }

  &-wrapper {
    max-width: 660px;
    margin: 30px auto 0;
    border-bottom: 1px solid transparent;

    .match-description {
      ::v-deep {
        ul {
          padding-left: 20px;
        }

        p,
        ul {
          margin-bottom: 0;
        }

        p + *,
        ul + * {
          margin-top: 1rem;
        }
      }

      @include max-tablet() {
        padding-left: 24;
        padding-right: 24;
      }

      .pvp-info-box + .pvp-info-box {
        margin-top: 1rem;
      }
    }

    .match-description + .ratings-tabs {
      margin-top: 30px;
    }
  }

  &-panel {
    text-align: center;
  }

  &-title {
    display: block;
    text-align: center;
    font-size: 22px;
    font-weight: bold;
  }

  &-state {
    display: block;
    text-align: center;
    font-size: 14px;
    color: rgba(white, 0.4);
  }
}

.info {
  &-title {
    display: block;
    font-size: 18px;
    font-weight: bold;
    text-align: center;

    & + span {
      margin-top: 12px;
    }
  }

  &-box {
    margin-top: 20px;
    border-radius: 2px;
    background-color: rgba(black, 0.2);
    padding: 24px 30px;
  }
}

.time-left {
  display: block;
  text-align: center;
  font-size: 30px;
  color: $azure;
  margin-top: 20px;

  &-text {
    text-align: center;
    font-size: 14px;
    color: rgba(white, 0.4);
    text-transform: lowercase;
  }
}

.loaded-img {
  margin-top: 30px;
  max-width: 100%;
}
</style>
import { update } from 'lodash';
